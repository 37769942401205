'use strict';

angular.module('enervexSalesappApp').controller('ContactsCtrl', function($scope, Account, Contact, $stateParams) {
	$scope.accountId = $stateParams.accountId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	fetchContacts();
	function fetchContacts(){
		Contact.query({
			accountId: $stateParams.accountId
		}).$promise.then(function(contacts){
			$scope.contacts = contacts;
		});
	}
	$scope.removeContact = function(contactToRemove) {
		Contact.remove({
			accountId: $stateParams.accountId,
			id: contactToRemove._id
		}).$promise.then(function() {
			fetchContacts();
		});
	}
});
